<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            :title="`Ingreso Diario - Granja: ${nombreGranja}`"
            :sub-title="
              'Ingreso de datos para el día ' +
              diaSubtitulo +
              ' - Saldo aves: ' +
              cantidad
            "
          >
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(guardar)">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Semana"
                      rules="required|not_selected"
                    >
                      <base-input label="Semana">
                        <select
                          v-model="datosDia.semana"
                          class="custom-select"
                          @change="handleSemana"
                        >
                          <option value="-1">(Seleccionar Semana)</option>
                          <option
                            v-for="semana in filteredSemanas"
                            :key="semana.id"
                            :value="semana.id"
                            :disabled="!semana.habilitada"
                          >
                            Semana
                            {{ semana.numero }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Dia"
                      rules="required|not_selected"
                    >
                      <base-input label="Dia">
                        <select
                          v-model="datosDia.dia"
                          class="custom-select"
                          @change="handleDia"
                        >
                          <option value="-1">(Seleccionar Dia)</option>
                          <option
                            v-for="dia in diasSelect"
                            :key="dia.id"
                            :value="dia.id"
                          >
                            {{ dia.nombre }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Mortalidad"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model="datosDia.mortalidad"
                        type="number"
                        label="Mortalidad"
                        placeholder="Mortalidad"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Selección"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model="datosDia.seleccion"
                        type="number"
                        label="Selección"
                        placeholder="Selección"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Ventas"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model="datosDia.ventas"
                        type="number"
                        label="Ventas"
                        placeholder="Ventas"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Traslado"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model="datosDia.traslado"
                        type="number"
                        label="Traslado"
                        placeholder="Traslado"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="datosDia.traslado > 0" class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Traslado a Galpón"
                      rules="required|not_selected"
                    >
                      <base-input label="Traslado a Galpón">
                        <select
                          v-model="datosDia.galpon_id"
                          class="custom-select"
                        >
                          <option value="-1">
                            (Seleccione Galpón Destino)
                          </option>
                          <option
                            v-for="galpon in filteredGalpones"
                            :key="galpon.id"
                            :value="galpon.id"
                          >
                            Galpón No.
                            {{ galpon.numero }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <h5 class="subtitle">Alimento</h5>
                <hr />
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Empaque Alimento Ingreso"
                    >
                      <base-input label="Empaque Alimento Ingreso">
                        <select
                          v-model="datosDia.empaqueAlimentoIngreso"
                          class="custom-select"
                          @change="updateTooltip"
                        >
                          <option value="">
                            (Seleccionar Empaque Alimento Ingreso)
                          </option>
                          <option
                            v-for="empaquealimento in empaquealimentos"
                            :key="empaquealimento.id"
                            :value="empaquealimento.id"
                          >
                            {{ empaquealimento.empaque_alimento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" style="border-left: 1px solid #ccc">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Empaque Alimento Consumo"
                      rules="required"
                    >
                      <base-input label="Empaque Alimento Consumo">
                        <select
                          v-model="datosDia.empaqueAlimentoConsumo"
                          class="custom-select"
                          @change="updateTooltip"
                        >
                          <option value="">
                            (Seleccionar Empaque Alimento Consumo)
                          </option>
                          <option
                            v-for="empaquealimento in empaquealimentos"
                            :key="empaquealimento.id"
                            :value="empaquealimento.id"
                          >
                            {{ empaquealimento.empaque_alimento }}
                          </option>
                        </select>
                      </base-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Alimento ingreso (Kg)"
                      rules="required|numeric|min_value:0"
                    >
                      <label>
                        {{ updatedLabelIngreso }}
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tooltipText_ingreso"
                          style="margin-left: 5px; cursor: pointer"
                        ></i>
                      </label>
                      <base-input
                        v-model="datosDia.alimento_ingreso"
                        type="number"
                        :placeholder="PlaceholderAlimentoIngreso"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" style="border-left: 1px solid #ccc">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Alimento (Kg)"
                      rules="required|numeric|min_value:0"
                    >
                      <label>
                        {{ updatedLabel }}
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tooltipText"
                          style="margin-left: 5px; cursor: pointer"
                        ></i>
                      </label>
                      <base-input
                        v-model="datosDia.alimento"
                        type="number"
                        :placeholder="PlaceholderAlimentoConsumo"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Valor"
                      rules="required"
                    >
                      <label>
                        Valor
                        <i
                          class="fas fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Ingrese un valor"
                          style="margin-left: 5px; cursor: pointer"
                        ></i>
                      </label>
                      <base-input
                        v-model="datosDia.valor"
                        type="text"
                        placeholder="Valor"
                        @input="formatearDinero"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <h5 v-if="tipo === 'postura'" class="subtitle">
                  Ingreso Postura
                </h5>
                <hr v-if="tipo === 'postura'" class="styled-hr" />
                <div v-if="tipo === 'postura'" class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura AAAA"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_aaaa"
                        type="number"
                        label="Postura AAAA"
                        placeholder="Postura AAAA"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura AAA"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_aaa"
                        type="number"
                        label="Postura AAA"
                        placeholder="Postura AAA"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura AA"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_aa"
                        type="number"
                        label="Postura AA"
                        placeholder="Postura AA"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="tipo === 'postura'" class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura A"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_a"
                        type="number"
                        label="Postura A"
                        placeholder="Postura A"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura B"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_b"
                        type="number"
                        label="Postura B"
                        placeholder="Postura B"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura C"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_c"
                        type="number"
                        label="Postura C"
                        placeholder="Postura C"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="tipo === 'postura'" class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura D"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_d"
                        type="number"
                        label="Postura D"
                        placeholder="Postura D"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura Yema"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_yema"
                        type="number"
                        label="Postura Yema"
                        placeholder="Postura Yema"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura Roto"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_roto"
                        type="number"
                        label="Postura Roto"
                        placeholder="Postura Roto"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4" style="margin-left: 10px">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Postura Sucio"
                      rules="required|numeric|min_value:0"
                    >
                      <base-input
                        v-model.number="datosDia.postura_sucio"
                        type="number"
                        label="Postura Sucio"
                        placeholder="Postura Sucio"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div style="margin-top: 40px; margin-left: 20px">
                    <small class="text-muted"
                      >Total Postura: {{ posturaTotal }}</small
                    >
                  </div>
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-info btn-fill float-right"
                    :disabled="loading || invalid"
                    @click.prevent="guardar"
                  >
                    Guardar
                  </button>
                </div>
                <div class="clearfix" />
              </form>
            </ValidationObserver>
            <div v-if="loading" class="loading-indicator">Cargando...</div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import Swal from "sweetalert2";
import { mapState } from "pinia";
import { useAuthUserStore } from "../stores/auth.store";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

const POSTURA = "postura";

export default {
  name: "IngresoDiario",
  components: { Card, ValidationProvider, ValidationObserver },
  props: {
    galponLoteId: {
      type: String,
      default: "",
      required: true,
    },
    tipo: {
      type: String,
      required: true,
      validator: function (value) {
        return ["levante", "postura"].includes(value);
      },
    },
  },
  data() {
    return {
      user: {
        company: "Light dashboard",
        username: "michael23",
        email: "",
        firstName: "Mike",
        lastName: "Andrew",
        address: "Melbourne, Australia",
        city: "melbourne",
        country: "Australia",
        postalCode: "",
        aboutMe:
          "Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo.",
      },
      semanas: [],
      empaquealimentos: [],
      cantidad: 0,
      tooltipText: "Seleccione un empaque alimento consumo",
      tooltipText_ingreso: "Seleccione un empaque alimento ingreso",
      dias: [],
      datosDia: {
        semana: -1,
        dia: -1,
        mortalidad: undefined,
        seleccion: undefined,
        ventas: undefined,
        traslado: undefined,
        alimento: undefined,
        alimento_ingreso: undefined,
        galpon_id: -1,
        postura_aaaa: undefined,
        postura_aaa: undefined,
        postura_aa: undefined,
        postura_a: undefined,
        postura_b: undefined,
        postura_c: undefined,
        postura_d: undefined,
        postura_yema: undefined,
        postura_roto: undefined,
        postura_sucio: undefined,
        empaqueAlimentoIngreso: "",
        empaqueAlimentoConsumo: "",
        valor: "",
      },
      galponData: {}, // Rename the conflicting variable
      galpones: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(useAuthUserStore, {
      authUser: (state) => state.user,
    }),
    nombreGranja() {
      return this.authUser?.granja?.nombre || "";
    },
    updatedLabelIngreso() {
      const selectedEmpaque = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoIngreso,
      );
      return selectedEmpaque
        ? `Alimento Ingreso (${selectedEmpaque.empaque_alimento})`
        : "Alimento Ingreso";
    },

    updatedLabel() {
      const selectedEmpaque = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoConsumo,
      );
      return selectedEmpaque
        ? `Alimento (${selectedEmpaque.empaque_alimento})`
        : "Alimento";
    },

    PlaceholderAlimentoConsumo() {
      const selectedEmpaque = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoConsumo,
      );
      return selectedEmpaque
        ? `Alimento (${selectedEmpaque.empaque_alimento})`
        : "Alimento";
    },

    PlaceholderAlimentoIngreso() {
      const selectedEmpaque = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoIngreso,
      );
      return selectedEmpaque
        ? `Alimento (${selectedEmpaque.empaque_alimento})`
        : "Alimento";
    },

    diaSubtitulo() {
      moment.locale("es");
      if (this.datosDia.dia !== -1) {
        const selectedDia = this.diasSelect.find(
          (dia) => dia.id === this.datosDia.dia,
        );
        return moment(selectedDia.fecha).format("DD [de] MMMM");
      }
      return "seleccionado";
    },
    diasSelect() {
      if (this.datosDia.semana === -1) return [];
      const semana = this.semanas.find(
        (semana) => semana.id === this.datosDia.semana,
      );
      return semana.dias_semana ?? [];
    },
    castedGalponLoteId() {
      return parseInt(this.galponLoteId, 10); // Ensure base 10 parsing
    },
    filteredGalpones() {
      return this.galpones.filter((galpon) => galpon.id !== this.galponData.id);
    },
    filteredSemanas() {
      return this.semanas.filter((semana) => semana.numero !== 0);
    },
    posturaTotal() {
      return (
        (this.datosDia.postura_aaaa || 0) +
        (this.datosDia.postura_aaa || 0) +
        (this.datosDia.postura_aa || 0) +
        (this.datosDia.postura_a || 0) +
        (this.datosDia.postura_b || 0) +
        (this.datosDia.postura_c || 0) +
        (this.datosDia.postura_d || 0) +
        (this.datosDia.postura_yema || 0) +
        (this.datosDia.postura_roto || 0) +
        (this.datosDia.postura_sucio || 0)
      );
    },
  },
  watch: {
    "datosDia.semana": "handleSemana",
    "datosDia.dia": "handleDia",
  },
  created() {
    if (!["levante", "postura"].includes(this.tipo)) {
      this.$router.push({ name: "Overview" });
    }
  },
  mounted() {
    this.verificarEstadoLote();
    this.cargarSemanas();
    this.cargarGalpones();
    this.CargarEmpaqueAlimentos();
  },
  methods: {
    formatearDinero() {
      if (typeof this.datosDia.valor === "string") {
        const value = this.datosDia.valor.replace(/\D/g, "");
        if (value) {
          this.datosDia.valor = `$${parseFloat(value).toLocaleString("es-CO")}`;
        } else {
          this.datosDia.valor = "";
        }
      }
    },

    updateTooltip() {
      const selectedIngreso = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoIngreso,
      );
      const selectedConsumo = this.empaquealimentos.find(
        (empaque) => empaque.id === this.datosDia.empaqueAlimentoConsumo,
      );

      if (selectedIngreso) {
        this.tooltipText_ingreso =
          selectedIngreso.empaque_alimento || "Empaque alimento no disponible";
      } else {
        this.tooltipText_ingreso = "Información sobre Alimento ingreso";
      }

      if (selectedConsumo) {
        this.tooltipText =
          selectedConsumo.empaque_alimento || "Empaque alimento no disponible";
      } else {
        this.tooltipText = "Información sobre Alimento consumo";
      }
    },

    async verificarEstadoLote() {
      try {
        const estadoResponse = await axios.get("/api/lote/user/lote-estado");
        const estado = estadoResponse.data.estado;

        if (estado === "EN_PROCESO") {
          await Swal.fire({
            icon: "info",
            title: "¡Atención!",
            text: "El lote aún se encuentra en proceso de creación en el sistema, por favor intente en unos minutos.",
            confirmButtonText: "Aceptar",
          });
          this.$router.push({ name: "Overview" });
        }
      } catch (error) {
        console.error("Error al verificar el estado del lote:", error);
      }
    },
    async cargarSemanas() {
      try {
        const { data } = await axios.get(
          `/api/levante/validarSemanas/${this.galponLoteId}`,
          {
            params: {
              tipo: this.tipo,
            },
          },
        );
        this.semanas = data;
        const lastEnabledWeek = this.filteredSemanas
          .filter((semana) => semana.habilitada)
          .at(-1);
        if (lastEnabledWeek) {
          this.datosDia.semana = lastEnabledWeek.id;
          this.seleccionarPrimerDiaConDatosCero();
        }
      } catch (error) {
        console.error("Error al cargar las semanas:", error);
      }
    },
    async cargarGalpones() {
      try {
        const { data } = await axios.get("/api/galpon");
        this.galpones = data;
        const galpon = data.find(
          (galpon) => galpon.lotes.at(0)?.pivot.id === this.castedGalponLoteId,
        );
        if (galpon) {
          this.galponData = galpon;
          this.cantidad = galpon.lotes.at(0)?.pivot?.cantidad || 0;
        }
      } catch (error) {
        console.error("Error al cargar los galpones:", error);
      }
    },

    async CargarEmpaqueAlimentos() {
      try {
        const { data } = await axios.get("/api/levante/empaquealimentos");
        this.empaquealimentos = data;
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        alert(
          "No se pudieron cargar los datos. Por favor, intenta nuevamente.",
        );
      }
    },

    cargarDatosDiaLocal(dia) {
      if (
        dia.mortalidad === 0 &&
        dia.seleccion === 0 &&
        dia.ventas === 0 &&
        dia.alimento === 0 &&
        dia.alimento_ingreso === 0 &&
        dia.traslado === 0
      ) {
        this.limpiarDatosDia();
      } else {
        this.datosDia.mortalidad = dia.mortalidad;
        this.datosDia.seleccion = dia.seleccion;
        this.datosDia.ventas = dia.ventas;
        this.datosDia.traslado = dia.traslado;
        this.datosDia.galpon_id = dia.galpon_id || -1;
        this.datosDia.empaqueAlimentoIngreso =
          dia.alimento_movimiento.empaque_alimento_ingreso_id;
        this.datosDia.empaqueAlimentoConsumo =
          dia.alimento_movimiento.empaque_alimento_consumo_id;
        this.datosDia.alimento = dia.alimento_movimiento.alimento_consumo;
        this.datosDia.alimento_ingreso =
          dia.alimento_movimiento.alimento_ingreso;
        this.datosDia.valor = dia.alimento_movimiento.valor
          ? `$${new Intl.NumberFormat("es-CO").format(dia.alimento_movimiento.valor)}`
          : "";

        if (this.tipo === POSTURA && dia.postura_dato) {
          this.datosDia.postura_aaaa = dia.postura_dato.postura_aaaa;
          this.datosDia.postura_aaa = dia.postura_dato.postura_aaa;
          this.datosDia.postura_aa = dia.postura_dato.postura_aa;
          this.datosDia.postura_a = dia.postura_dato.postura_a;
          this.datosDia.postura_b = dia.postura_dato.postura_b;
          this.datosDia.postura_c = dia.postura_dato.postura_c;
          this.datosDia.postura_d = dia.postura_dato.postura_d;
          this.datosDia.postura_yema = dia.postura_dato.postura_yema;
          this.datosDia.postura_roto = dia.postura_dato.postura_roto;
          this.datosDia.postura_sucio = dia.postura_dato.postura_sucio;
        }
      }
    },
    handleSemana() {
      this.datosDia.dia = -1;
      this.limpiarDatosDia();
      this.seleccionarPrimerDiaConDatosCero();
    },
    handleDia() {
      const diaSeleccionado = this.diasSelect.find(
        (dia) => dia.id === this.datosDia.dia,
      );
      this.cargarDatosDiaLocal(diaSeleccionado);
    },

    async guardar() {
      this.loading = true;
      try {
        const { dia, semana, ...data } = this.datosDia;
        if (data.valor) {
          data.valor = parseFloat(data.valor.replace(/[$.,]/g, ""));
        }
        const endpoint = `api/levante/semana/${semana}/dia/${dia}`;
        await axios.put(endpoint, data);
        this.$router.push({ name: "Overview" });
      } catch (error) {
        if (error.response) {
          console.error("Error response:", error.response);

          if (error.response.status === 400) {
            await Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response.data.error,
              confirmButtonText: "Aceptar",
            });
          } else if (error.response.status === 500) {
            // Show detailed server error message
            await Swal.fire({
              icon: "error",
              title: "Error del Servidor",
              text:
                error.response.data.message || "Ocurrió un error inesperado.",
              confirmButtonText: "Aceptar",
            });
          } else {
            await Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo comunicar con el servidor.",
              confirmButtonText: "Aceptar",
            });
          }
        } else {
          console.error("Error al guardar los datos:", error);
          await Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se pudo comunicar con el servidor.",
            confirmButtonText: "Aceptar",
          });
        }
      } finally {
        this.loading = false;
      }
    },

    limpiarDatosDia() {
      this.datosDia = {
        semana: this.datosDia.semana,
        dia: this.datosDia.dia,
        mortalidad: undefined,
        seleccion: undefined,
        ventas: undefined,
        traslado: undefined,
        alimento: undefined,
        alimento_ingreso: undefined,
        galpon_id: -1,
        postura_aaaa: undefined,
        postura_aaa: undefined,
        postura_aa: undefined,
        postura_a: undefined,
        postura_b: undefined,
        postura_c: undefined,
        postura_d: undefined,
        postura_yema: undefined,
        postura_roto: undefined,
        postura_sucio: undefined,
        empaqueAlimentoIngreso: "",
        empaqueAlimentoConsumo: "",
        valor: undefined,
      };
    },
    seleccionarPrimerDiaConDatosCero() {
      const primerDiaConDatos = this.diasSelect.find(
        (dia) =>
          dia.mortalidad === 0 &&
          dia.seleccion === 0 &&
          dia.ventas === 0 &&
          dia.alimento === 0 &&
          dia.alimento_ingreso === 0 &&
          dia.traslado === 0,
      );
      if (primerDiaConDatos) {
        this.datosDia.dia = primerDiaConDatos.id;
      }
    },
  },
};
</script>
<style scoped>
.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #333;
}

.styled-hr {
  width: 100%;
  margin: 20px 0;
}
.subtitle {
  margin: 20px 0;
  color: #9a9a9a;
}
</style>
