<template>
  <div class="wrapper">
    <side-bar title="Henhaus">
      <div class="user-info">
        <span class="nav-link">{{ userName }} - {{ nombreCliente }}</span>
      </div>
      <hr class="separator" />
      <mobile-menu slot="content" />
      <sidebar-link to="/admin/overview">
        <i class="nc-icon nc-chart-pie-35" />
        <p>Registro Diario</p>
      </sidebar-link>

      <sidebar-link v-if="isAdminOrRoot" to="/admin/lote">
        <i class="nc-icon nc-notes" />
        <p>Lotes</p>
      </sidebar-link>

      <sidebar-link to="/admin/pesaje">
        <i class="nc-icon nc-chart-bar-32" />
        <p>Control Peso</p>
      </sidebar-link>

      <sidebar-link v-if="isRoot" to="/admin/cliente">
        <i class="nc-icon nc-single-02" />
        <p>Clientes</p>
      </sidebar-link>

      <sidebar-link v-if="isAdminOrRoot" to="/admin/granja">
        <i class="nc-icon nc-notes" />
        <p>Granja</p>
      </sidebar-link>

      <sidebar-link to="/admin/Clasificado">
        <i class="nc-icon nc-chart-pie-35" />
        <p>Huevo Clasificado</p>
      </sidebar-link>
      <template slot="bottom-links" />
    </side-bar>

    <div class="main-panel">
      <top-navbar />

      <dashboard-content @click="toggleSidebar" />

      <content-footer />
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu.vue";
import { useAuthUserStore } from "../stores/auth.store.js";
import { mapState } from "pinia";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  computed: {
    ...mapState(useAuthUserStore, [
      "user",
      "isRoot",
      "isAdmin",
      "isAdminOrRoot",
      "isOperario",
    ]),
    userName() {
      return this.user.name || "Usuario";
    },
    nombreCliente() {
      return this.user.cliente.nombre || "No disponible";
    },
    isMobileMenuActive() {
      return this.$sidebar.showSidebar;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 990px) {
  .user-info {
    display: none;
  }
  .separator {
    display: none;
  }
  .user-info-mobile {
    display: block;
  }
}
.separator {
  border-top: 1px solid #ffffff;
}
</style>
