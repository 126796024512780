<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card
            header-classes="d-flex justify-content-between"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title m-0 pt-2">
                {{ tituloTabla }}
              </h4>
              <div>
                <div class="d-flex justify-content-between">
                  <div>
                    <button
                      class="btn btn-primary btn-fill mr-2"
                      :disabled="
                        selectedGalpon == -1 || selectedOption === 'Periodo'
                      "
                      @click="handleIngresar"
                    >
                      Ingresar
                    </button>
                  </div>
                  <div class="d-flex">
                    <select
                      v-model.number="selectedGalpon"
                      class="custom-select custom-select-lg mr-2"
                      style="width: 130px"
                      @change="handleGalponChange"
                    >
                      <option value="-1">General</option>
                      <option
                        v-for="galpon in galpones"
                        :key="galpon.id"
                        :value="galpon.id"
                      >
                        Galpon {{ galpon.numero }}
                      </option>
                    </select>
                    <select
                      v-model="selectedOption"
                      class="custom-select custom-select-lg"
                      style="width: 130px"
                      @change="handleOptionChange"
                    >
                      <option value="Periodo">Periodo</option>
                      <option value="levante">Levante</option>
                      <option value="postura">Postura</option>
                    </select>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="selectedOption === 'Periodo'" class="text-center my-4">
              <p class="text-muted">Seleccione Periodo</p>
            </div>
            <div
              v-if="selectedOption !== 'Periodo' && datosTabla.datosSemana"
              class="table-hover table-striped table-bordered"
              style="overflow-x: auto"
            >
              <div class="table-container">
                <table class="table table-sm">
                  <thead>
                    <th class="text-center" style="width: 84px">Sem</th>
                    <th class="text-center">Dia</th>
                    <th class="text-center">Mort.</th>
                    <th class="text-center">Sel.</th>
                    <th class="text-center">Ventas</th>
                    <th v-if="selectedGalpon !== -1" class="text-center">
                      Traslado
                    </th>
                    <th v-if="selectedGalpon !== -1" class="text-center">
                      Ingreso
                    </th>
                    <th class="text-center">Ali Cons<br />Kg</th>
                    <th class="text-center">
                      Ali ing<br />
                      Kg
                    </th>
                    <th class="text-center">Saldo Ali<br />Kg</th>
                    <th class="text-center">Saldo<br />Aves</th>

                    <template v-if="selectedOption === 'postura'">
                      <th class="text-center">Postura<br />AAAA</th>
                      <th class="text-center">Postura<br />AAA</th>
                      <th class="text-center">Postura<br />AA</th>
                      <th class="text-center">Postura<br />A</th>
                      <th class="text-center">Postura<br />B</th>
                      <th class="text-center">Postura<br />C</th>
                      <th class="text-center">Postura<br />D</th>
                      <th class="text-center">Postura<br />Yema</th>
                      <th class="text-center">postura<br />Roto</th>
                      <th class="text-center">Postura<br />Sucio</th>
                      <th class="text-center">Postura<br />Total</th>
                      <th class="text-center">Prod<br />%</th>
                    </template>
                  </thead>
                  <tbody v-if="!datosTabla.cargando && datosTabla.datosSemana">
                    <template v-for="semana in datosTabla.datosSemana">
                      <tr
                        v-for="(diaSemana, index) in semana.dias"
                        :key="diaSemana.id"
                      >
                        <td v-if="index == 0" rowspan="9" align="center">
                          <span class="h2">{{ semana.numero }}</span>
                        </td>
                        <td align="center" style="padding-left: 6px">
                          {{ diaSemana.nombre }} <br />
                          {{ diaSemana.fecha }}
                        </td>
                        <td align="center">
                          {{ diaSemana.mortalidad }}
                        </td>
                        <td align="center">
                          {{ diaSemana.seleccion }}
                        </td>
                        <td align="center">
                          {{ diaSemana.ventas }}
                        </td>
                        <td v-if="selectedGalpon !== -1" align="center">
                          {{ diaSemana.traslado }}
                        </td>
                        <td v-if="selectedGalpon !== -1" align="center">
                          {{ diaSemana.ingreso }}
                        </td>
                        <td align="center">
                          {{ diaSemana.alimento }}
                        </td>
                        <td
                          align="center"
                          class="hoverable"
                          title=""
                          :class="{
                            'has-movements': hasMovements(diaSemana),
                          }"
                          @mouseover="showTooltip(diaSemana, $event.target)"
                        >
                          {{ diaSemana.alimento_ingreso }}
                        </td>
                        <td align="center">
                          {{ diaSemana.saldo_alimento }}
                        </td>
                        <td align="center">
                          {{ diaSemana.saldo_aves }}
                        </td>
                        <template v-if="selectedOption === 'postura'">
                          <td align="center">
                            {{ diaSemana.postura_aaaa }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_aaa }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_aa }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_a }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_b }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_c }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_d }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_yema }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_roto }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_sucio }}
                          </td>
                          <td align="center">
                            {{ diaSemana.postura_total }}
                          </td>
                          <td align="center">
                            {{ diaSemana.prod_perc }}
                          </td>
                        </template>
                      </tr>
                      <template v-if="semana.numero !== 0">
                        <tr :key="'tot-' + semana.id">
                          <td class="font-weight-bold" align="center">
                            T. Sem
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.totales.mortalidad }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.totales.seleccion }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.totales.ventas }}
                          </td>
                          <td
                            v-if="selectedGalpon !== -1"
                            class="font-weight-bold"
                            align="center"
                          >
                            {{ semana.totales.traslado }}
                          </td>
                          <td
                            v-if="selectedGalpon !== -1"
                            class="font-weight-bold"
                            align="center"
                          >
                            {{ semana.totales.ingreso }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.totales.alimento }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.totales.alimento_ingreso }}
                          </td>
                          <td align="center" rowspan="2">
                            {{ semana.saldo_alimento }}
                          </td>
                          <td align="center" rowspan="2">
                            {{ semana.saldo_aves }}
                          </td>
                          <template v-if="selectedOption === 'postura'">
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aaaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_aa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_a }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_b }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_c }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_d }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_yema }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_roto }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.totales.postura.postura_sucio }}
                            </td>
                            <td
                              class="font-weight-bold table-cell-auto"
                              align="center"
                            >
                              {{ semana.totales.postura.postura_total }}
                            </td>
                            <td align="center" rowspan="2">
                              {{ semana.totales.postura.prod_perc }}
                            </td>
                          </template>
                        </tr>
                        <tr :key="'acum-' + semana.id">
                          <td class="font-weight-bold" align="center">Acum</td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.acumulados.mortalidad }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.acumulados.seleccion }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.acumulados.ventas }}
                          </td>
                          <td
                            v-if="selectedGalpon !== -1"
                            class="font-weight-bold"
                            align="center"
                          >
                            {{ semana.acumulados.traslado }}
                          </td>
                          <td
                            v-if="selectedGalpon !== -1"
                            class="font-weight-bold"
                            align="center"
                          >
                            {{ semana.acumulados.ingreso }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.acumulados.alimento }}
                          </td>
                          <td class="font-weight-bold" align="center">
                            {{ semana.acumulados.alimento_ingreso }}
                          </td>
                          <template v-if="selectedOption === 'postura'">
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_aaaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_aaa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_aa }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_a }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_b }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_c }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_d }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_yema }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_roto }}
                            </td>
                            <td class="font-weight-bold" align="center">
                              {{ semana.acumulados.postura_sucio }}
                            </td>
                            <td
                              class="font-weight-bold table-cell-auto"
                              align="center"
                            >
                              {{ semana.acumulados.postura_total }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-if="datosTabla.cargando" class="text-center my-4">
                <p class="text-muted">Cargando tabla...</p>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Card from "../components/Cards/Card.vue";

export default {
  components: { Card },
  data() {
    return {
      datosTabla: {
        datosSemana: [],
        cargando: false,
      },
      galpones: [],
      selectedGalpon: -1,
      selectedOption: "Periodo",
    };
  },
  computed: {
    tituloTabla() {
      if (this.selectedOption === "levante") {
        return "Información Cria/Levante";
      } else if (this.selectedOption === "postura") {
        return "Información Cria/Postura";
      }
      return "Información Cria/Levante";
    },
  },
  mounted() {
    this.cargarDatos();
    this.cargarGalpones();
  },
  methods: {
    hasMovements(diaSemana) {
      return (
        diaSemana.alimento_movimientos &&
        Object.keys(diaSemana.alimento_movimientos).length > 0
      );
    },
    showTooltip(diaSemana, elementoAlimentoIngreso) {
      elementoAlimentoIngreso.removeAttribute("title");
      if (diaSemana.alimento_movimientos) {
        const movimiento = diaSemana.alimento_movimientos;
        const tooltipText = `
        Empaque:${movimiento.empaque_alimento_ingreso}
        Valor: ${movimiento.valor}
        Cantidad: ${movimiento.alimento_ingreso}
    `;
        elementoAlimentoIngreso.setAttribute("title", tooltipText);
      }
    },

    async cargarDatos() {
      let galponToRequest = "";
      if (this.selectedGalpon !== -1) {
        const galpon = this.galpones.find(
          (galpon) => galpon.id === this.selectedGalpon,
        );
        if (galpon && galpon.lotes?.length > 0) {
          galponToRequest = galpon.lotes.at(0).pivot.id;
        }
      }

      this.datosTabla.cargando = true;
      const { data } = await axios.get(
        "/api/levante/tabla/" + galponToRequest,
        {
          params: {
            tipo: this.selectedOption,
          },
        },
      );
      this.datosTabla = data;
      this.datosTabla.cargando = false;
    },
    async cargarGalpones() {
      const { data } = await axios.get("/api/galpon");
      this.galpones = data;
    },
    handleGalponChange() {
      this.cargarDatos();
    },
    handleOptionChange() {
      this.cargarDatos();
    },

    handleIngresar() {
      if (this.selectedGalpon === -1 || this.selectedOption === "") {
        return;
      }
      this.$router.push({
        name: "Ingreso",
        params: {
          galponLoteId: this.selectedGalpon.toString(),
          tipo: this.selectedOption,
        },
      });
    },
  },
};
</script>

<style scoped>
.card .table tbody td:last-child {
  display: table-cell;
  width: auto;
  padding-right: 6px;
}

.card .table thead th:last-child {
  display: table-cell;
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  z-index: 1;
}

.table-sm td,
.table-sm th {
  padding: 0.1rem 0.2rem;
}

.hoverable {
  cursor: default;
}

.has-movements {
  cursor: pointer;
}

.has-movements:hover {
  text-decoration: underline;
}
</style>
